let uniqueCounter = 0;

import {computed, reactive, ref, watchEffect, nextTick} from 'vue';
export default {
    install (app) {

        app.config.globalProperties.serverPrefetch = function () {
            console.log("global server prefetch")
        };
        app.mixin({
            methods : {
                refreshAsyncDataIfRequired : function () {
                    // todo: foreach source, check if it has changed. if so - update it


                    this.refreshAsyncData();
                },
                refreshAsyncData : function (keys = null) {
                  if ( ! this.asyncOps ) {
                      debug('Warning - you set up async data, but did not provide asyncOps dependency in install (). Operation has been halted to prevent fatal error.', 2, {component: this});
                      return;
                  }

                  if (typeof keys !== 'object' || keys == null) {
                      keys = null;
                  }

                  if ( ! this.asyncData) {
                      return;
                  }

                  return this.asyncOps.fetchAsyncData(this.asyncData, this, keys);

                },
                watchAsyncDataRequirements : function () {
                    // this watches any changes to async data
                    this.$watch('asyncData', function (newValue, oldValue) {
                       this.refreshAsyncDataIfRequired();
                    }, {deep:true});

                    // inside asyncData, there may be keys, where the data to be sent with the request is a funciton, subscribe to it

                    for (const [key, value] of Object.entries(this.asyncData)) {
                        let watchable = false;
                        // in case value is string, it does not have nested data, move along
                        if (typeof value !== 'object') {
                            continue;
                        }

                        // value of data is not a function, it is not dynamic. move along
                        if (typeof value.data !== 'function') {
                            continue;
                        }
                    //    console.log(value.data);
                    }

                },
                hasAsyncData : function () {
                   // TODO: this triggers  vue warn in ssr

                    if ( ! this.$data) {
                        return false;
                    }
                    if ( ! this.$data.asyncData) {
                        return false;
                    }



                    return  !! this.asyncOps;
                },
                populateAsyncDataAsGetters : function () {
                    if ( ! this.hasAsyncData()) {
                        return true;
                    }

                    for (const [key, value] of Object.entries(this.asyncData)) {
                        let storeKey = `${uniqueCounter}-${this.$.uid}-${key}`;
                        let payload = {
                            key: storeKey,
                            value: this[key]
                        };
                        uniqueCounter++;


                        if (typeof this.asyncData[key] !== 'object') {
                            this.asyncData[key] = {
                                target: this.asyncData[key]
                            }
                        }

                        // TODO: we can reuse keys and then build up into some sort of cache...
                        this.asyncData[key].storeKey = storeKey;
                        this.$store.commit('asyncData/generic', payload);
                        this[key] = computed(() => {return this.$store.getters['asyncData/generic'](storeKey)});
                    }
                },
                /**
                 * Basic method to await asyncOperations asyncData fetching
                 * @returns {Promise<any>}
                 */
                getSSRPrefetchPromise : function () {
                    return new Promise((fulfil) => {
                        // wait one tick at least
                        nextTick(() => {
                            // if we shoudnt wait, have not asyncOps, or are not loading data - we are good to go
                            if ( ! config.useSSR || ! this.asyncOps || ! this.asyncOps.asyncStatus.asyncDataLoading) {
                                fulfil();
                                return;
                            }

                            // wait - watch for when the data is ready
                            watchEffect(() => {
                                // watch asyndDataCleared
                                if (this.asyncOps.asyncStatus.asyncDataClear) {
                                    fulfil();
                                }
                            });
                        });
                    });
                }
            },
            updated () {

            },
            created () {
                if (this.hasAsyncData()) {
                    this.populateAsyncDataAsGetters();
                    this.watchAsyncDataRequirements();

                    // TODO: handle hydration
                    this.refreshAsyncData();
                }
            },
            mounted () {

            },

            serverPrefetch () {
            //   console.log('server prefetch mixin');

            },
            watch : {

            }

        })
    },

}
